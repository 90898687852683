@tailwind base;
@tailwind components;
@tailwind utilities;

.card {
  border-radius: 6px;
  transition: 0.3s;
  transform-style: preserve-3d;
  position: relative;
  cursor: pointer;
  border-color: #42ade2d4;
  margin: 1px;
}
.card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 2px;
}
.card .card-face {
  backface-visibility: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
}
.card .card-face.card-back-face {
  transform: rotateY(180deg);
}
.card.is-flipped {
  transform: rotateY(180deg);
}
.card.is-inactive {
  opacity: 100;
}

.bold {
  font-weight: 600;
}
.App {
  width: 100%;
  height: 100%;
}
.App header {
  position: relative;
  width: 100%;
  text-align: center;
}
.App header > div {
  font-size: 15px;
  width: 324px;
  text-align: center;
  margin: 0 auto;
}

.App footer .score {
  justify-content: center;
  display: flex;
}
.App footer .score div {
  padding: 8px;
}
.App footer .restart {
  display: flex;
  justify-content: center;
}
.App .container {
  background-color: #ffffffd9;
  
  border-radius: 6px;
  margin: 0 auto;
}

.direction {
  font-weight: 700 !important;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;
}

.direction div{
  font-weight: 500 !important;
  text-align: left;
  padding: 5px 5px;
  cursor: pointer;
  border-bottom: 1px solid black;
  width: 100%;
  line-height: 1.2rem;
}
