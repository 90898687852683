.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Common */

.fix {
  zoom: 1;
}

.fix:after {
  display: table;
  clear: both;
  content: "";
}

/* Home */

.wrap {
  margin: 0 auto;
  text-align: center;
  position: relative;
}

.logo,
#gameArea {
  margin-bottom: 40px;
}

.ws-area,
.ws-words {
  display: inline-block;
  vertical-align: top;
  
}

.ws-words {
  text-align: left;
}

.ws-word {
  margin-bottom: 4px;
}

.ws-words ::first-letter {
  text-transform: capitalize;
}

/* Wordsearch */

.ws-area {
  background: #fafafa;
  display: inline-block;

  border-radius: 10px;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.ws-row {
  line-height: 0;
  display: flex;
  justify-content: center;
}

.ws-col {
  cursor: pointer;
}

.ws-col.ws-selected {
  background: #cacaca;
}

.ws-found {
  background: yellow;
}

.ws-game-over-outer {
  background: rgba(0, 0, 0, 0.85);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.ws-game-over-inner {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  display: table;
}

.ws-game-over {
  display: table-cell;
  vertical-align: middle;
}

.ws-game-over h2 {
  color: #ffffff;
  font-size: 2em;
  text-transform: uppercase;
  padding: 0;
  margin: 0 0 9px 0;
}

.ws-game-over p {
  color: #ffffff;
  font-size: 1em;
  padding: 0;
  margin: 0;
}

.crossword .correct {
  text-decoration: line-through;
  color: rgb(85, 185, 85)
}
