.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}
.App:focus {
  outline: none;
}
.setting {
  align-self: flex-start;
  padding: 5px 10px;
}
.setting button {
  font-size: 15px;
}
.setting input {
  font-size: 20px;
}

.wall {
  border: 2px solid transparent;
}

.topWall {
  border-top: 2px solid white;
}
.rightWall {
  border-right: 2px solid white;
}
.bottomWall {
  border-bottom: 2px solid white;
}
.leftWall {
  border-left: 2px solid white;
}
.sol div {
  height: 50%;
  width: 50%;
  border-radius: 50%;
  background: peachpuff;
  display: inline-block;
}
.currentPosition div {
    background-color: white;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(https://eventqiu.com/assets/CPL_Tile.jpg);
  height: 100%;
  width: 100%;
  display: block;
}
.destination div {
  background: #00ff00;
  height: 100%;
  width: 100%;
  border-radius: 0%;
  display: block;
}
.currentPosition.destination div {
  animation: rainbowBall 5s infinite;
}
@keyframes rainbowBall {
  0% {
    background-color: gold;
  }
  15% {
    background-color: coral;
  }
  29% {
    background-color: papayawhip;
  }
  43% {
    background-color: darkseagreen;
  }
  57% {
    background-color: lightcyan;
  }
  71% {
    background-color: dodgerblue;
  }
  84% {
    background-color: violet;
  }
  100% {
    background-color: indigo;
  }
}
#maze {
  margin-top: 15px;
  border-collapse: collapse;
}
#maze td {
  height: 20px;
  width: 20px;
}
.info {
  margin-top: 20px;
  width: 100%;
  background: cornsilk;
  padding: 3px;
  font-size: 20px;
}
.info p {
  text-transform: uppercase;
  color: forestgreen;
}
